import request from './axios'

export default {
	getQRcodeHash(params) {
		return request.get(`qrcode/getQRHash`, { params })
	},
	getStore(params) {
		return request.get(`/front/qrcode/getStore`, { params })
	},
}
