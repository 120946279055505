import router from '@/router'
import store from '@/store'
import { alert as $alert } from '../../utils/alert'

export default {
	state: {
		kioskOrderRemark: '',
		isCancelOrderDialog: false,
		version: process.env.VUE_APP_VERSION,
		maintenance: {
			modal: false, // 彈窗
			title: '', //標題
			message: '', //訊息
		},
	},
	mutations: {
		setMaintenance($, val) {
			$.maintenance = val
		},
	},
	actions: {
		guidePage(ctx) {
			const random = router.history.current.params.random
			if (random) {
				if (store.state.kiosk.is) {
					store.commit('kiosk/SET_IS', true)
					if (router.history.current.path !== `/${random}/Refresh`) {
						router.push(`/${random}/Refresh?kiosk=${random}`)
					}
				} else {
					if (router.history.current.path !== `/${random}/Store`) {
						router.push(`/${random}/Store`)
					}
				}
				let countdown = 3 // 倒數計時初始值
				const message = () =>
					`系統連線過期!，為保護您的資料安全，請重新刷新頁面。`

				// 顯示初始提示訊息
				$alert(message(), 'danger')

				// 更新提示訊息並進行倒數
				const interval = setInterval(() => {
					countdown--
					if (countdown > 0) {
						$alert(`${countdown} 秒後將自動重新整理！`, 'danger') // 每秒更新訊息
					} else {
						clearInterval(interval) // 停止倒數
						location.reload() // 重新整理頁面
					}
				}, 1000)
			} else {
				router.push('/null/Error')
			}
		},
	},
}
