<template>
	<div>
		<div v-for="upgradeContent in upgrade.upgradeContentList">
			<div
				class="combo__name__expend"
				@click="
					$set(upgradeContent, 'isShowFlavor', !upgradeContent.isShowFlavor)
				"
			>
				<span
					>{{ upgradeContent.name }}
					<span class="tag" v-if="upgradeContent.leastQuantity > 0">*</span>
					<span
						class="quantityText"
						:class="{ active: upgradeContent.leastQuantity > 0 }"
					>
						{{ getQuantityText(upgradeContent) }}
					</span>
				</span>
				<img
					src="../../../assets/back.svg"
					class="arrow"
					:class="{ active: upgradeContent.isShowFlavor }"
				/>
			</div>

			<div class="errorValidate" v-if="upgradeContent.errorValidate">
				- 請選擇餐點
			</div>

			<BySlider v-show="upgradeContent.isShowFlavor">
				<div>
					<div
						v-for="upgradeContentDetail in upgradeContent.upgradeContentDetailList"
					>
						<ByRadio
							class="detail_name__text"
							v-if="upgradeContent.mostQuantity === 1"
							:name="String(upgradeContentDetail.upgradeContentDetailId)"
							:title="upgradeContentDetail.itemName"
							:required="upgradeContent.leastQuantity === 1"
							v-model="upgradeContent.select[0]"
							:disabled="!upgradeContentDetail.availableForSale"
						>
							<span class="flavor-price">
								{{
									upgradeContentDetail.itemName && upgradeContentDetail.price
										? `+$ ${$stepPrice(upgradeContentDetail.price)}`
										: ''
								}}
							</span>
						</ByRadio>

						<ByCheckbox
							class="detail_name__text"
							:class="{
								disabled:
									maxUpgradeCount(
										upgradeContent,
										upgradeContentDetail.upgradeContentDetailId,
									) === 0,
							}"
							v-if="
								upgradeContent.mostQuantity > 1 ||
								upgradeContent.mostQuantity === null
							"
							:name="String(upgradeContentDetail.upgradeContentDetailId)"
							:title="upgradeContentDetail.itemName"
							:disabled="
								maxUpgradeCount(
									upgradeContent,
									upgradeContentDetail.upgradeContentDetailId,
								) === 0 || !upgradeContentDetail.availableForSale
							"
							:value="
								upgradeContent.select.includes(
									String(upgradeContentDetail.upgradeContentDetailId),
								)
							"
							@change="
								(value, ev) =>
									handleChangeUpgradeContent(
										value,
										ev,
										upgradeContent,
										upgradeContentDetail,
									)
							"
						>
							<div style="display: flex; align-items: center">
								<ByAmountPicker
									class="product-amount"
									:min="1"
									:max="
										maxUpgradeCount(
											upgradeContent,
											upgradeContentDetail.upgradeContentDetailId,
										)
									"
									v-model="upgradeContentDetail.count"
									v-if="
										upgradeContent.select.includes(
											String(upgradeContentDetail.upgradeContentDetailId),
										)
									"
									@onIncrease="
										updateUpgradeContentDetailCount(
											upgradeContentDetail,
											'onIncrease',
										)
									"
									@onMinus="
										updateUpgradeContentDetailCount(
											upgradeContentDetail,
											'onMinus',
										)
									"
                  @updateCount="updateUpgradeContentDetailCount(upgradeContentDetail, 'onInputUpdate', upgradeContentDetail.count)"
								/>
								<span
									class="flavor-price"
									style="width: 80px; text-align: right"
								>
									{{
										upgradeContentDetail.itemName && upgradeContentDetail.price
											? `+$ ${$stepPrice(upgradeContentDetail.price)}`
											: ''
									}}
								</span>
							</div>
						</ByCheckbox>
						<div
							v-for="i in upgradeContentDetail.count"
							:key="'contentDetailCount' + i"
						>
							<div
								class="option__name__expend"
								v-if="
									upgradeContent.select.includes(
										String(upgradeContentDetail.upgradeContentDetailId),
									) &&
									upgradeContentDetail.optionList &&
									upgradeContentDetail.optionList.length
								"
								@click="
									handleUpgradeContentDetailIsShowFlavor(
										upgradeContentDetail,
										i - 1,
									)
								"
							>
								<span
									>選擇口味-{{ upgradeContentDetail.itemName }}({{ i }})</span
								>
								<img
									src="../../../assets/back.svg"
									class="arrow"
									:class="{ active: upgradeContentDetail.isShowFlavor[i - 1] }"
								/>
							</div>
							<BySlider
								v-if="
									upgradeContent.select.includes(
										String(upgradeContentDetail.upgradeContentDetailId),
									) &&
									upgradeContentDetail.optionList &&
									upgradeContentDetail.optionList.length > 0
								"
								v-show="upgradeContentDetail.isShowFlavor[i - 1]"
							>
								<Flavor
									upgrade
									:data="upgradeContentDetail.optionSaveList[i - 1] || []"
								/>
							</BySlider>
						</div>
					</div>
				</div>
			</BySlider>
		</div>
	</div>
</template>

<script>
import Flavor from '@/views/buy/Detail/Flavor'
import {
	isUpgradeContentAllNotSale,
	isComboContentAllNotSale,
} from '@/utils/order'
export default {
	components: { Flavor },
	props: {
		_upgrade: {
			type: Object,
		},
	},
	data() {
		return {}
	},
	computed: {
		upgrade: {
			get() {
				return this._upgrade
			},
			set(value) {
				this.$emit('update:_upgrade', value)
			},
		},
	},
	methods: {
		getQuantityText(content) {
			const { leastQuantity, mostQuantity } = content
			if (isUpgradeContentAllNotSale(content)) {
				return ``
			}
			if (leastQuantity === 0) {
				if (mostQuantity) {
					return `可選${mostQuantity}項`
				} else {
					return ``
				}
			} else {
				if (leastQuantity === mostQuantity) {
					return `必選${leastQuantity}項`
				} else {
					if (mostQuantity) {
						return `必選${leastQuantity}~${mostQuantity}項`
					} else {
						return `必選${leastQuantity}項以上`
					}
				}
			}
		},
		maxUpgradeCount(upgradeContent, upgradeContentDetailId) {
			if (upgradeContent.mostQuantity) {
				let count = 0
				upgradeContent.upgradeContentDetailList.forEach(
					upgradeContentDetail => {
						if (
							upgradeContent.select.includes(
								String(upgradeContentDetail.upgradeContentDetailId),
							) &&
							upgradeContentDetailId !==
								upgradeContentDetail.upgradeContentDetailId
						) {
							count += upgradeContentDetail.count
						}
					},
				)
				return upgradeContent.mostQuantity - count
			}
		},
		handleChangeUpgradeContent(
			value,
			ev,
			upgradeContent,
			upgradeContentDetail,
		) {
			if (upgradeContent.select.includes(value)) {
				upgradeContentDetail.count = 1
				upgradeContent.select = upgradeContent.select.filter(v => v !== value)
			} else {
				if (upgradeContent.mostQuantity) {
				}
				upgradeContent.select.push(value)
			}
		},
		updateUpgradeContentDetailCount(upgradeContentDetail, status, inputCount) {
			console.log(upgradeContentDetail, status)
			switch (status) {
				case 'onIncrease':
					upgradeContentDetail.isShowFlavor.push(true)
					upgradeContentDetail.optionSaveList.push(
						JSON.parse(JSON.stringify(upgradeContentDetail.optionList)),
					)
					break
				case 'onMinus':
					upgradeContentDetail.isShowFlavor.pop()
					upgradeContentDetail.optionSaveList.pop()
					break
        case 'onInputUpdate':
          // 计算需要添加或删除的数量
          const delta = inputCount - upgradeContentDetail.isShowFlavor.length;
          if (delta > 0) {
            // 如果输入的数量大于当前数组长度，添加元素
            for (let i = 0; i < delta; i++) {
              upgradeContentDetail.isShowFlavor.push(true);
              upgradeContentDetail.optionSaveList.push(
                  JSON.parse(JSON.stringify(upgradeContentDetail.optionList)),
              );
            }
          } else {
            // 如果输入的数量小于当前数组长度，删除元素
            for (let i = 0; i < Math.abs(delta); i++) {
              upgradeContentDetail.isShowFlavor.pop();
              upgradeContentDetail.optionSaveList.pop();
            }
          }
          break;
			}
		},
		handleUpgradeContentDetailIsShowFlavor(upgradeContentDetail, index) {
			this.$set(
				upgradeContentDetail.isShowFlavor,
				index,
				!upgradeContentDetail.isShowFlavor[index],
			)
		},
	},
}
</script>

<style scoped lang="sass">
::v-deep .by-checkbox label
  width: 100%
%comboName
  font-size: 16px
  font-weight: bold
  display: flex
  align-items: center

.option__name__expend,.combo__name__expend
  font-size: 16px
  font-weight: bold
  height: 25px
  flex: 1
  padding: 0 20px
  cursor: pointer
  margin-top: 20px
  display: flex
  justify-content: space-between
  position: relative
  outline: none
  img
    transition: all 0.3s
    transform: rotate(-90deg)
    width: 18px
    height: 18px
.tag
  margin: 0px 0
  font-size: 12.5px
  padding: 0 0px 0 5px
  color: red
  position: absolute
  top: -3px

.quantityText
  color: gray
  margin-left: 5px
  font-size: 12.5px
  font-weight: normal
  &.active
    color: red
    margin-left: 10px
.arrow
  &.active
    transform: rotate(90deg)

.errorValidate
  font-size: 12.5px
  color: red
  margin-left: 20px
//margin-top: 5px

.combo
  margin: 20px
  &__item
    padding-top: 10px


    &__name

      padding-bottom: 10px
      font-size: 16px
      font-weight: bold

      > .price
        margin-left: auto

      margin-bottom: 10px
    .detail_name__text
      font-weight: normal
      margin: 12px 0
      margin-left: 20px
      &.disabled
        filter: grayscale(1)
        opacity: 0.3
    >.name
      @extend %comboName
      font-weight: normal
      margin: 12px 0
      &__text
        display: flex
        flex-direction: column

        align-items: flex-start
        flex: 1

        .flavor-price
          display: flex
          align-items: center
        .content
          width: 100%
          margin-top: 10px
          font-size: 14px
          color: #8E8E8E
        &::v-deep
          label
            flex: 1
      &__black
        width: 18px
        height: 18px
        margin-left: 8px
      &__expend
        height: 18px
        width: 18px
        cursor: pointer
        margin-left: 8px
        img
          transition: all 0.3s
          transform: rotate(-90deg)
          width: 100%
          height: 100%
          &.active
            transform: rotate(90deg)
      .flavor
        padding: 12px
        background: rgba(#000, .02)
        border-radius: 8px
        &::v-deep
          .flavor
            width: 100%
            padding-top: 12px
            &:first-child
              padding-top: 0
            &:last-child
              border-bottom: 0
              padding-bottom: 0
</style>
