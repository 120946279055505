<template>
	<div>
		<div class="container">
			<div class="content">
				<img src="../../assets/order_pending.png" alt="payment" />
				<p>確認付款狀態中...</p>
			</div>
		</div>
		<ByFooter />
	</div>
</template>

<script>
export default {
	name: 'OrderConfirm',
	created() {
		const storeHash = this.$route.params.random
		const query = this.$route.query
		this.$api.order
			.confirmTransaction(query.code, {
				storeHash: storeHash,
				orderId: query.orderId,
				transactionId: query.transactionId,
			})
			.then(res => {
				const {
					data: { hashId, paymentStatus, message },
				} = res

				if (paymentStatus === 'SUCCEEDED') {
					this.$store.commit('user/SET_CART_ITEM', [])
					this.$store.commit('myOrder/SET_FINISH_ORDER', true)
					this.$alert('付款成功', 'success')
					this.$router.push('TakeMeal/' + hashId)
				} else if (paymentStatus === 'FAILED') {
					this.$alert('付款失敗', 'danger')
					this.$router.replace(`/${this.$route.params.random}/Order`)
				} else {
					this.$alert(message)
					this.$router.replace(`/${this.$route.params.random}/Order`)
				}
			})
			.catch(err => {
				this.$alert('付款出現異常!', 'danger')
				this.$router.replace(`/${this.$route.params.random}/Order`)
			})
	},
}
</script>

<style scoped>
.container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

img {
	max-width: 100%;
	height: auto;
}

p {
	margin-top: 20px;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
}
</style>
