<template>
	<div>
		<div class="line-bar">
			<div
				:style="{ color: isWhite ? '#FFF' : 'rgba(67,67,67,0.8)' }"
				@click="goAbout"
			>
				關於點溡
			</div>
			<div
				:style="{ color: isWhite ? '#FFF' : 'rgba(67,67,67,0.8)' }"
				@click="goService"
			>
				服務條款
			</div>
			<div
				:style="{ color: isWhite ? '#FFF' : 'rgba(67,67,67,0.8)' }"
				@click="goPrivacy"
			>
				隱私權政策
			</div>
			<div>
				<a
					class="link"
					:style="{ color: isWhite ? '#FFF' : 'rgba(67,67,67,0.8)' }"
					:href="$store.state.store.uri"
					type="_blank"
					>聯絡點溡</a
				>
			</div>
		</div>
		<div
			class="version"
			:style="{ color: isWhite ? '#FFF' : 'rgba(51,51,51,0.5)' }"
		>
			{{ version }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'ByFooter',
	props: {
		isWhite: {
			required: false,
			type: Boolean,
		},
	},
	data() {
		return {}
	},
	computed: {
		version() {
			return this.$store.state.version
		},
	},
	mounted() {},
	methods: {
		goPrivacy() {
			this.$router.push('Privacy')
		},
		goService() {
			this.$router.push('Service')
		},
		goAbout() {
			this.$router.push('About')
		},
	},
}
</script>

<style lang="sass" scoped>
.logo
	width: 100%
	+fc

	img
		width: 100px
		height: auto

.line-bar
	display: flex
	flex-wrap: wrap
	justify-content: space-around
	margin: 8px auto 0
	width: fit-content

	> div
		margin: 5px 0
		font-size: 15px

		&:not(:last-child)
			margin-right: 23px
			position: relative

			&::after
				content: ''
				border-left: 1px solid #C4C4C4
				height: 10px
				position: absolute
				right: -12px
				top: 1px

.company
	text-align: center
	color: #a2a2a2
	font-size: 14px
	padding: 7.5px 0

.version
	text-align: center
	margin-top: 5px
	margin-right: 10px
	padding-bottom: 20px
	font-size: 14px
	user-select: none
	pointer-events: none
	z-index: 999

.link
	cursor: default

.link:link
	text-decoration: none
//background-color: #ffffff

.link:visited
//color: #ffffff
//background-color: red

.link:hover
	text-decoration: none
	background-color: #fafafa
	color: gray

.link:active
	text-decoration: none
	background-color: gray
	color: #fafafa
</style>
