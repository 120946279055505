var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.upgrade.upgradeContentList),function(upgradeContent){return _c('div',[_c('div',{staticClass:"combo__name__expend",on:{"click":function($event){return _vm.$set(upgradeContent, 'isShowFlavor', !upgradeContent.isShowFlavor)}}},[_c('span',[_vm._v(_vm._s(upgradeContent.name)+" "),(upgradeContent.leastQuantity > 0)?_c('span',{staticClass:"tag"},[_vm._v("*")]):_vm._e(),_c('span',{staticClass:"quantityText",class:{ active: upgradeContent.leastQuantity > 0 }},[_vm._v(" "+_vm._s(_vm.getQuantityText(upgradeContent))+" ")])]),_c('img',{staticClass:"arrow",class:{ active: upgradeContent.isShowFlavor },attrs:{"src":require("../../../assets/back.svg")}})]),(upgradeContent.errorValidate)?_c('div',{staticClass:"errorValidate"},[_vm._v(" - 請選擇餐點 ")]):_vm._e(),_c('BySlider',{directives:[{name:"show",rawName:"v-show",value:(upgradeContent.isShowFlavor),expression:"upgradeContent.isShowFlavor"}]},[_c('div',_vm._l((upgradeContent.upgradeContentDetailList),function(upgradeContentDetail){return _c('div',[(upgradeContent.mostQuantity === 1)?_c('ByRadio',{staticClass:"detail_name__text",attrs:{"name":String(upgradeContentDetail.upgradeContentDetailId),"title":upgradeContentDetail.itemName,"required":upgradeContent.leastQuantity === 1,"disabled":!upgradeContentDetail.availableForSale},model:{value:(upgradeContent.select[0]),callback:function ($$v) {_vm.$set(upgradeContent.select, 0, $$v)},expression:"upgradeContent.select[0]"}},[_c('span',{staticClass:"flavor-price"},[_vm._v(" "+_vm._s(upgradeContentDetail.itemName && upgradeContentDetail.price ? `+$ ${_vm.$stepPrice(upgradeContentDetail.price)}` : '')+" ")])]):_vm._e(),(
								upgradeContent.mostQuantity > 1 ||
								upgradeContent.mostQuantity === null
							)?_c('ByCheckbox',{staticClass:"detail_name__text",class:{
								disabled:
									_vm.maxUpgradeCount(
										upgradeContent,
										upgradeContentDetail.upgradeContentDetailId,
									) === 0,
							},attrs:{"name":String(upgradeContentDetail.upgradeContentDetailId),"title":upgradeContentDetail.itemName,"disabled":_vm.maxUpgradeCount(
									upgradeContent,
									upgradeContentDetail.upgradeContentDetailId,
								) === 0 || !upgradeContentDetail.availableForSale,"value":upgradeContent.select.includes(
									String(upgradeContentDetail.upgradeContentDetailId),
								)},on:{"change":(value, ev) =>
									_vm.handleChangeUpgradeContent(
										value,
										ev,
										upgradeContent,
										upgradeContentDetail,
									)}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(
										upgradeContent.select.includes(
											String(upgradeContentDetail.upgradeContentDetailId),
										)
									)?_c('ByAmountPicker',{staticClass:"product-amount",attrs:{"min":1,"max":_vm.maxUpgradeCount(
											upgradeContent,
											upgradeContentDetail.upgradeContentDetailId,
										)},on:{"onIncrease":function($event){return _vm.updateUpgradeContentDetailCount(
											upgradeContentDetail,
											'onIncrease',
										)},"onMinus":function($event){return _vm.updateUpgradeContentDetailCount(
											upgradeContentDetail,
											'onMinus',
										)},"updateCount":function($event){return _vm.updateUpgradeContentDetailCount(upgradeContentDetail, 'onInputUpdate', upgradeContentDetail.count)}},model:{value:(upgradeContentDetail.count),callback:function ($$v) {_vm.$set(upgradeContentDetail, "count", $$v)},expression:"upgradeContentDetail.count"}}):_vm._e(),_c('span',{staticClass:"flavor-price",staticStyle:{"width":"80px","text-align":"right"}},[_vm._v(" "+_vm._s(upgradeContentDetail.itemName && upgradeContentDetail.price ? `+$ ${_vm.$stepPrice(upgradeContentDetail.price)}` : '')+" ")])],1)]):_vm._e(),_vm._l((upgradeContentDetail.count),function(i){return _c('div',{key:'contentDetailCount' + i},[(
									upgradeContent.select.includes(
										String(upgradeContentDetail.upgradeContentDetailId),
									) &&
									upgradeContentDetail.optionList &&
									upgradeContentDetail.optionList.length
								)?_c('div',{staticClass:"option__name__expend",on:{"click":function($event){return _vm.handleUpgradeContentDetailIsShowFlavor(
										upgradeContentDetail,
										i - 1,
									)}}},[_c('span',[_vm._v("選擇口味-"+_vm._s(upgradeContentDetail.itemName)+"("+_vm._s(i)+")")]),_c('img',{staticClass:"arrow",class:{ active: upgradeContentDetail.isShowFlavor[i - 1] },attrs:{"src":require("../../../assets/back.svg")}})]):_vm._e(),(
									upgradeContent.select.includes(
										String(upgradeContentDetail.upgradeContentDetailId),
									) &&
									upgradeContentDetail.optionList &&
									upgradeContentDetail.optionList.length > 0
								)?_c('BySlider',{directives:[{name:"show",rawName:"v-show",value:(upgradeContentDetail.isShowFlavor[i - 1]),expression:"upgradeContentDetail.isShowFlavor[i - 1]"}]},[_c('Flavor',{attrs:{"upgrade":"","data":upgradeContentDetail.optionSaveList[i - 1] || []}})],1):_vm._e()],1)})],2)}),0)])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }