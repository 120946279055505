<template>
	<div>
		<ToolBar :class="currentPath" />
		<div v-if="envTag && $route.name === 'Store'" class="env-tag">
			{{ envTag }}
		</div>
		<div class="main-page" v-if="!$store.state.maintenance.modal">
			<router-view />
		</div>
		<MyMaintenanceModal />
	</div>
</template>

<script>
import ToolBar from '../layout/ToolBar.vue'
import MyMaintenanceModal from '../../components/MaintenanceModal.vue'

export default {
	components: { MyMaintenanceModal, ToolBar },
	computed: {
		currentPath() {
			let pathName = this.$route.name
			switch (pathName) {
				case 'Store':
					return ''
				default:
					return 'addShadow'
			}
		},
		envTag() {
			return process.env.VUE_APP_ENV_TAG || ''
		},
	},

	mounted() {},
	// beforeDestroy() {},
	methods: {},
}
</script>

<style lang="sass" scoped>
.main-page
	margin-top: 50px

.addShadow
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16)

.env-tag
	position: fixed
	top: 10px
	left: 10px
	background-color: red
	color: white
	padding: 5px 10px
	font-size: 14px
	font-weight: bold
	border-radius: 4px
	z-index: 1000
</style>
